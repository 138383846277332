import * as React from "react"
import { Helmet} from 'react-helmet';


import config from "../utils/config";

//styles
import "../styles/css/styles.css"




// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import defaultsData from '../utils/formStructure';
import Form from "../components/shared/Form";
import Search from "../components/Search/index";

import Contact from "../components/Contact";

export default function Contato() {

  return(
    <div>
      <Helmet>
          <title>Solicitação de visita técnica - Wega</title>
          <link rel="shortcut icon" href={config.favicon} type="image/x-icon"></link>
      </Helmet>
        <Header />
        <Search />
        <div className="main-form">
            <div className="container contato-title">
                <h1>Solicitacao de palestra ou Treinamento</h1>
            </div>
            <section className="contato-custom">
                <Form type="Solicitacao de palestra ou Treinamento" structure={defaultsData.SPT} />
            
            </section>
        </div>
        <Footer />
    </div>
  )
}

export const Head = () => <title>Wega Motors</title>